.navbar.navbar-header {
    background-color: $base-white;
    // justify-content: center !important;
    color: $base-white;
    padding: 18px !important;
    box-shadow: 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);

    a img.bg-brand{
        width: 100px;
        // width: 150px;
        // height: 33px;
    }
    
    .navbar-light {
        border: none;
    }

    .navbar-toggler {
        position: absolute;
        right: 10px;
        top: 10px;
        border: none;

        .navbar-toggler-icon {
            filter: brightness(0) invert(0);
            background-image: url('../images/icon/hamburger.ico');
        }
    }

    .navbar-toggler:hover,.navbar-toggler:focus,.navbar-toggler:active, 
    .navbar-toggler:active:focus:not(:disabled):not(.disabled),
    .btn:focus, .btn:active, .btn:hover{
        box-shadow: none!important;        
        outline: 0;
    }

    #basic-navbar-nav {
        position: absolute !important;
        z-index: 10;
        top: 0;
        left: 0;
        background: white;
        background: #FFFFFF;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 0px 0px 20px 20px;
        padding: unset!important;
        max-width: $max-screen;
        width: 100%;

        .navbar-nav {
            .navbar-caret {
                margin-bottom: 15px;
                cursor: pointer;
                text-align: right;
                padding: 8px 20px;
            }

            a {                
                color: $base-white;
                text-align: center;
                font-family: 'Roboto', sans-serif;
                padding: 15px !important;
                font-weight: 500;
                text-transform: uppercase;
                // font-size: 16px;
            }
            a:hover:not(:last-child){
                background-color: rgb(247, 247, 247);
                // opacity: 0.6;
                // box-shadow: 0px 22.3363094329834px 17.869047164916992px 0px #0000000B;
                text-decoration: none;
            }
            a.last:last-child{
                // background: #CA2D2D;
                // border-radius: 0  0 20px 20px;
            }
            a.last:hover:last-child{
                background: #CA2D2D;
                border-radius: 0  0 20px 20px;
                color: white!important;
                opacity: 0.6;
            }
        }
    }

    .navbar-expand-lg .navbar-toggler {
        display: inline;
    }
}