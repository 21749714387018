$base-white: #FFFFFF;
$base-yellow: #FFE600;
$base-yellow-hover: #e4ce0d;
$base-black: #666666;
$base-red: #D53934;
$base-green: #27AE60;
$base-half-black: #727272;
$base-true-black: #000000;
$base-background: #F6F6F6;
$base-gray: #888888;

$base-width-sm: 480px;
$base-width-super-sm: 320px;
$base-width-md: 768px;
$max-screen: 480px;

@import './auth';
@import './container';
@import './program';
@import './profile';

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
    font-family: 'Poppins-Regular';
    src: local('Poppins-Regular'), url(../fonts/Poppins/Poppins-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins-Light';
    src: local('Poppins-Light'), url(../fonts/Poppins/Poppins-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins-Bold';
    src: local('Poppins-Bold'), url(../fonts/Poppins/Poppins-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins-Medium';
    src: local('Poppins-Medium'), url(../fonts/Poppins/Poppins-Medium.ttf) format('truetype');
}

.container{
    padding-left: 25px !important;
    padding-right: 25px !important;
}

.container.px-0{
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.wrapper {
    width: 100%;
    max-width: $max-screen;
    min-height: 100vh;
    margin: 0 auto;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    position: relative;
    background-color: $base-background;
}

.custom-alert {
    text-align: left;
    font-size: 12px;
    border: none !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    border-radius: 5px !important; 
    color: $base-white !important;
    padding: .55rem 1.25rem !important;
    background-color: $base-red !important;  
}

.navbar {
    position: relative !important;
    display: flex !important;
    flex-wrap: wrap !important;
    align-items: center !important;
}

.navbar-expand-lg .navbar-nav {
    flex-direction: column !important;
}

.navbar-expand-lg .navbar-toggler {
    display: inline !important;
}

// .custom-collapse:not(.show) {
//     display: none !important;
// }

.custom-collapse {
    flex-basis: 100% !important;
    text-align: center;

    .navbar-nav {
        a {
            color: $base-true-black !important;
            // padding: 8px !important;

            img {
                width: 150px;
                height: 33px;
            }
        }
    }

    .navbar-expand {
        padding: 10px !important;
    }
}

.custom-collapse.show {
    display: inline !important;
}

.custom-collapse.not-show {
    display: none !important;
}

.modal-dialog.modal-width{
    max-width: 470px;
}

.title-form{
    margin-bottom: 15px;
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.6px;
    font-style: italic;
}

.info-list {
    color: #888888;
    font-weight: 600;
    font-size: small;
    font-size: 14px;
    ol {
        padding-left: 1.5rem;
        li {
            margin-bottom: 5px;
        }
    }
}

.btn-primary-yellow, .btn-primary-yellow.btn-primary {
    border-radius: 6px;
    border: none;
    color: $base-true-black;
    font-family: 'Roboto', sans-serif;
    margin-bottom: .5rem;
    font-weight: 500;
    background-color: $base-yellow;
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
    -webkit-box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
    -moz-box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
}
.btn-primary-yellow:hover, .btn-primary-yellow:active, .btn-primary-yellow.btn-primary:hover, .btn-primary-yellow.btn-primary:active{
    background-color: $base-yellow-hover;
    color: $base-true-black;
}

.daterangepicker td.active, .daterangepicker td.active:hover{
    background-color: #CA2D2D !important;
}

.card-expand {
    display: none;
    max-width: $max-screen;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 10;
    // top: 0;
    // height: 95vh;
    // background-color: white;
    // overflow-y: auto;
    // border-top-left-radius: 20px;
    // border-top-right-radius: 20px;
    // box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.25);
    .card-expand-wrapper {
        position: absolute;
        bottom: 0;
        max-height: 75vh;
        background-color: white;
        overflow-y: auto;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        box-shadow: 2px 1px 4px rgba(0, 0, 0, 0.25);
        padding: 0 20px;
        left: 0;
        right: 0;
        
        .form-header-list {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            margin-bottom: 16px;
            .alert {
                width: 100%;
                margin-bottom: 0;
            }
            .form-collapse{
                cursor: pointer;
                width: 23px;
                height: 20px;
                background: url('../images/icon/caret_up.svg') no-repeat;
                // background-size: cover;
                object-fit: cover;
                text-align: center;
                margin-right: 4px;
                margin-left: 14px;
            }
        }

        .title-form {
            color: $base-gray;
            font-weight: 700;
            font-size: 22px;
        }

        .form-button{
            display: flex;
            margin-bottom: 25px;
            .btn {
                box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
                border-radius: 3px;
                color: white;
                font-size: 13px;
                font-weight: 700;
            }
            .btn-danger {
                background: #CA2D2D;
            }

            .btn-danger:hover{
                background: #b92020;
            }

            .btn-success{
                background: rgba(97, 133, 50, 0.69);
                border-color: rgba(97, 133, 50, 0.69);
            }
            .btn-success:hover{
                background: rgba(64, 92, 27, 0.69);
                border-color: rgba(64, 92, 27, 0.69);
            }

            .btn-outline-light{
                border: 1px solid #888888;
                color: #888888;
            }

            .btn-warning {
                background: $base-yellow;
                box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
                border-radius: 3px;
                color: $base-true-black;
                font-size: 13px;
                font-weight: 700;
                border-color: $base-yellow;
            }

            .btn-warning:hover{
                background: $base-yellow-hover;
            }
        }

        .btn-full{
            margin-top: 23px;
            .btn:first-child{
                margin-left: 0;
            }
            .btn:last-child{
                margin-right: 0;
            }
            .btn{
                margin: 0 5px;
            }
        }

        .form-data {
            .form-group {
                margin-right: 88px;
            }
        }

        .form-group {
            font-family: 'Roboto', sans-serif;
            position: relative;
            .form-label {
                color: $base-gray;
                font-weight: 700;
            }
            .form-control{
                border: 2px solid #C4C4C4;
                padding: 5px 12px;
                font-size: 14px;
                font-weight: 600;
            }

            textarea.form-control {
                min-height: 100px;
            }

            .form-icon {
                position: absolute;
                right: 10px;
                top: 40px;
            }

            .form-icon.form-calendar::after{
                display: block;
                content: '';
                width: 15px;
                height: 15px;
                background-image: url('../images/icon/calendar_imput.svg');
                background-repeat: no-repeat;
                background-size: cover;
            }

            .text-hidden{
                font-weight: 700;
                font-size: 15px;
                font-style: normal;
            }

            small{
                font-family: 'Open Sans', sans-serif;
            }

            .fileCustom {
                input:read-only {
                    background-color: white;
                    border-radius: .25rem;
                }

                label {
                    position: absolute;
                    right: -85px;
                    background: #888888;
                    border-radius: 3px;
                    margin-bottom: 0;
                    color: white;
                    padding: 5px 10px;
                }
            }

            .custom.custom-file {
                position: relative;
                width: 100%;
                .custom-file-label{
                    border: 2px solid #C4C4C4;
                    padding: 5px 12px;
                    font-size: 14px;
                    font-weight: 600;
                    // width: 90%;
                }

                .custom-file-label::after {
                    background: #888888;
                    border-radius: 3px;
                    // right: 0;
                    color: white;
                }
            }
            .code-icon{
                position: absolute;
                right: 8px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    .card-expand-wrapper::-webkit-scrollbar{
        width: 8px;
        height: 8px;
    }
    .card-expand-wrapper::-webkit-scrollbar-thumb{
        background: #C7C1C7;
        border-radius: 0px;
    }
    .card-expand-wrapper::-webkit-scrollbar-thumb:hover{
        background: #A1A1A1;
    }
    .card-expand-wrapper::-webkit-scrollbar-track{
        background: #F0F0F0;
        border-radius: 0px;
        box-shadow: inset 0px 0px 0px 0px #F0F0F0;
        border-top-left-radius: 20px;
    }

    @media only screen and (max-width: $base-width-sm) {
        .card-expand-wrapper{
            padding: 0;
        }
    }

}

.card-expand.show{
    display: block;
}

body.notoverflow{
    overflow: hidden;
}


.alert.alert-message{
    font-family: 'Roboto', sans-serif;
    border-radius: 7px;
    color: #FFFFFF;
    .alert-heading{
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 12px;
        color: #FFFFFF;
    }
    .alert-body, p, ol{
        font-size: 13px;
        margin-bottom: 0;
    }
}

.alert.alert-message.alert-danger{
    background: rgba(223, 18, 1, 0.69);
}

.alert.alert-message.alert-success{
    background: rgba(97, 133, 50, 0.69);
}

.card-file{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction:row!important;
    padding: 20px 15px;
    margin-bottom: 25px;
    background: #F9F7F7!important;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    .title{
        font-weight: 700;
        font-size: 15px;
        color: $base-gray;
    }

    .button {
        display: flex;
        align-items: center;
        span {
            color: $base-gray;
            font-weight: 600;
            font-size: 12px;
            margin-right: 8px;
            display: inline-block;
        }
        a, .btn {
            border-color: #FFE600;
            background: #FFE600;
            box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
            border-radius: 3px;
            width: 35px;
            height: 35px;
            display: inline-block;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        a:hover, .btn:hover {
            background-color: #d6c20b;
            border-color: #d6c20b;
        }
        a::after, .btn::after{
            content: '';
            display: block;
            background-image: url('../images/icon/download.svg');
            width: 17px;
            height: 18px;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
}

.rating-list {
    display: flex;
    align-items: center;
    justify-content: center;
    .rating-item::after {
        content: '';
        display: inline-block;
        height: 35px;
        width: 35px;
        background: url('../images/icon/star.svg') no-repeat;
        background-size: cover;
        margin: 0 5px;
        cursor: pointer;
    }

    .rating-item.active::after{
        background: url('../images/icon/star_gold.svg') no-repeat;
        background-size: cover;
    }
    .rating-item.hover::after{
        background: url('../images/icon/star_gold.svg') no-repeat;
        background-size: cover;
        opacity: 0.5;
    }
}

span.form-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}