.profile {
    font-family: 'Roboto', sans-serif;

    header {
        width: 100%;
        min-height: 218px;
        background: url('../images/header_image.png') no-repeat;
        background-size: 100% auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
        align-items: center;
        padding: 20px;

        .title{
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            color: $base-true-black;
            width: 100%;
            border-bottom: 2px solid $base-gray;
            font-size: 22px;
            width: fit-content;
            padding-bottom: 5px;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .greatins{
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            width: 100%;
            color: $base-true-black;
            margin-top: 15px;
        }

        .name {
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 25px;
            width: 100%;
            color: $base-true-black;
            margin-top: 5px;
        }

        .email {
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            width: 100%;
            color: $base-true-black;
            margin-top: 5px;
        }

        h1 {
            font-size: 24px;
            font-family: 'Roboto', sans-serif;
            color: $base-true-black;
            text-align: center;
            text-transform: uppercase;
        }
    }

    header.custom{
        display: block;
    }

    .btn {
        border-radius: 3px;
        padding: 10px 16px;
        font-weight: 600;
        color: #FFFFFF;
        font-family: 'Roboto', sans-serif;
        font-size: 13px;
        margin-left: 8px;  
    }

    .btn-edit {
        border-color: $base-yellow;
        background: $base-yellow;
        color: $base-true-black;
    }
    .btn-edit:hover{
        border-color: $base-yellow-hover;
        background: $base-yellow-hover;
        color: $base-true-black;
    }

    .btn-danger{
        background: #CA2D2D;
        // box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.25);
        border-radius: 3px;
        padding: 10px 16px;
        font-weight: 600;
        color: #FFFFFF;
        font-family: 'Roboto', sans-serif;
        font-size: 13px;
        margin-left: 8px;
        max-width: 100px;
    }

    .button-more {
        text-align: center;
        a {
            color: #262D33;
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            margin-top: 10px;
            font-weight: 500;
            display: inline-block;
        }

        .btn-danger{
            background: #CA2D2D;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            max-width: unset;
            width: 100%;
            margin-bottom: 10px;
        }
    }

    .btn-danger:hover{
        background: #a71d1d;
    }

    .card-status {
        .card-status-flex {
            display: flex;
            // overflow: auto;
            // border-bottom: 1px solid #000;
            // padding-bottom: 10px;
        }
        ::-webkit-scrollbar{
            width: 13px;
            height: 13px;
            }
        ::-webkit-scrollbar-thumb{
            background: transparent;
            border-radius: 0px;
        }
        ::-webkit-scrollbar-thumb:hover{
            background: transparent;
        }
        ::-webkit-scrollbar-track{
            background: transparent;
            border-radius: 0px;
            box-shadow: inset 0px 0px 0px 0px #F0F0F0;
        }

        .card-status-wrapper {
            display: inline-block;
            padding: 10px;

            .card-status-body {
                width: 80px;
                height: 80px;
                display: flex;
                margin: auto;
                font-size: 9px;
                border-radius: 5px;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                background-color: $base-white;
                box-shadow: 0px 4px 16px rgba(0,0,0,0.15);
                -webkit-box-shadow: 0px 4px 16px rgba(0,0,0,0.15);
                -moz-box-shadow: 0px 4px 16px rgba(0,0,0,0.15);
    
                img {
                    width: 50px;
                    height: 50px;
                    padding: 5px;
                }
                @media only screen and (max-width: $base-width-sm) {
                    width: 60px;
                    height: 80px;
                }
            }

            p {
                margin: 0;
                margin-top: 10px;
                font-size: 12px;
                text-align: center;
                font-family: 'Roboto', sans-serif;
                font-weight: 600;
            }
        }
    }

    .profile-list {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 10px;

        p {
            margin: 0;
        }

        .profile-label {
            color: $base-gray;
            font-weight: 500;
            margin-bottom: 5px;
        }

        .profile-data {
            background-color: #E5E5E5;
            color: $base-true-black;
            font-weight: 700;
            padding: 8px 15px;
            border-radius: 5px;
        }
    }

    @media only screen and (max-width: $base-width-sm) {
        header {
            min-height: 180px;
        }
    }
}