.program-page {
    font-family: 'Roboto', sans-serif;
    header {
        width: 100%;
        min-height: 218px;
        background: url('../images/header_image.png') no-repeat;
        background-size: 100% auto;
        display: flex;
        justify-content: left;
        align-items: center;
        padding: 15px 25px;

        h1 {
            font-size: 24px;
            font-family: 'Roboto', sans-serif;
            color: $base-true-black;
            // padding: 10px;
            text-align: center;
            text-transform: uppercase;
            // font-size: 30px;
            // font-family: 'Roboto', sans-serif;
            // color: #000000;
            // text-align: center;
            // text-transform: uppercase;
            // // max-width: 100px;
            // font-weight: 700;
        }
        .title{
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            color: $base-true-black;
            width: 100%;
            border-bottom: 2px solid $base-gray;
            font-size: 22px;
            width: fit-content;
            padding-bottom: 5px;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .greatins{
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            width: 100%;
            color: $base-true-black;
            margin-top: 15px;
        }

        .name {
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 25px;
            width: 100%;
            color: $base-true-black;
            margin-top: 5px;
        }

        .email {
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            width: 100%;
            color: $base-true-black;
            margin-top: 5px;
        }
    }
    
    header.customImg::after {
        display: block;
        background: attr('data-backgorund');
    }

    header.custom{
        display: block;
        padding: 20px;
    }

    .custom-card {
        // background: #FFFFFF;
        // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        // border-radius: 5px;
        // margin-top: 10px;
        font-family: 'Roboto', sans-serif;
        // padding-right: 10px;
        // min-height: 110px;
        // position: relative;
        padding: 15px 0;

        .col-8 {
            padding: 5px 15px 15px 0px;
        }

        .custom-card-img:not(.skeleton) {
            background: #FFFFFF;
            border-radius: 7px;
            padding: 20px;
            
            img {
                max-width: 100%;
                object-fit: cover;
            }
        }

        .custom-card-body {
            h5 {
                font-size: 15px;
                font-family: 'Roboto', sans-serif;
                font-weight: 600;
            }

            p {
                font-size: 12px;
                margin-bottom: 0;
                display: flex;
                align-items: center;
                color: $base-gray;
                font-weight: 600;
            }

            p.icon::before{
                display: inline-block;
                content: '';
                background-image: url('../images//icon/callendar.svg');
                width: 14px;
                height: 14px;
                background-size: cover;
                background-repeat: no-repeat;
                vertical-align: baseline;
                margin-right: 5px;
            }
        }

        .badge {
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }

    .custom-card:not(:last-child){
        border-bottom: 2px solid #888888;
    }
    .btn-danger{
        background: #CA2D2D;
        // box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.25);
        border-radius: 3px;
        padding: 10px 16px;
        font-weight: 600;
        color: #FFFFFF;
        font-family: 'Roboto', sans-serif;
        font-size: 13px;
        // margin-left: 8px;
        min-width: 100px;
        max-width: 100px;
    }

    .button-more {
        text-align: center;
        a {
            color: #262D33;
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            margin-top: 10px;
            font-weight: 500;
            display: inline-block;
        }

        .btn-danger{
            background: #CA2D2D;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            max-width: unset;
            width: 100%;
            margin-bottom: 10px;
        }
    }

    .btn-danger:hover{
        background: #a71d1d;
    }
    .payment-form {
        font-family: 'Open Sans', sans-serif;
        .payement-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #F9F7F7;
            box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
            border-radius: 3px;
            color: $base-gray;
            padding: 14px 20px;
            margin-bottom: 1.5rem;
            font-family: 'Roboto', sans-serif;
            
            h3 {
                font-size: 16px;
                margin-bottom: 0;
                font-weight: 500;
                font-family: 'Roboto', sans-serif;
            }
            h3:last-child{
                font-weight: 900;
            }
        }

        p {
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 14px;
            color: #888888;
            margin-bottom: 1.5rem;
        }

        .info-payment {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 2.5rem;

            img {
                // width: 90px;
                height: auto;
                margin-right: 5px;
            }

            div{
                text-align: right;
                
                span{
                    font-family: 'Open Sans', sans-serif;
                    display: block;
                    color: $base-gray;
                    font-weight: 600;
                }
                span:first-child{
                    font-size: 14px;
                }
                span:nth-child(2){
                    font-size: 13px;
                }
            }
        }

        .form-button {
            padding-bottom: 5rem;
        }
    }

    .program-title{
        font-size: 20px;
        font-weight: 500;
        color: #000000;
        padding-top: 20px;
        text-transform: uppercase;
        font-family: 'Roboto', sans-serif;
        border-bottom: 3px solid #888888;
        padding-bottom: 15px;
    }

    .program-status {
        /* text-align: center; */
        margin-bottom: 10px;
        text-align: left;
        text-transform: capitalize;
        font-weight: 500;
        font-family: 'Roboto', sans-serif;
    }


    form {
        .alert.danger {
            font-size: 12px;
            color: $base-white;
            background-color: $base-red;
        }

        .alert.dark {
            font-size: 12px;
            color: $base-white;
            background-color: $base-half-black;
        }

        .alert.success {
            font-size: 12px;
            color: $base-white;
            background-color: $base-green;
        }
        
        .form-footer {
            text-align: left;
        }

        .btn-primary {
            // width: 250px;
            height: 40px;
            border-radius: 6px;
            border: none;
            // color: $base-black;
            font-family: 'Poppins-Medium';
            margin-bottom: .5rem;
            background-color: $base-yellow;
            box-shadow: 0px 4px 16px rgba(0,0,0,0.25);
            -webkit-box-shadow: 0px 4px 16px rgba(0,0,0,0.25);
            -moz-box-shadow: 0px 4px 16px rgba(0,0,0,0.25);
        }

        .btn-outline-secondary {
            width: 250px;
            height: 40px;
            border-radius: 6px;
            box-shadow: 0px 4px 16px rgba(0,0,0,0.25);
            -webkit-box-shadow: 0px 4px 16px rgba(0,0,0,0.25);
            -moz-box-shadow: 0px 4px 16px rgba(0,0,0,0.25);
            margin-top: 15px;
        }

        .custom-file-error {
            color: $base-red;
            font-size: 12px;
            margin-top: 4px;
        }
    }

    .data-not-found {
        margin-top: 2rem;
        text-align: center;
    }

    .btn-primary-yellow {
        border-radius: 6px;
        border: none;
        color: $base-true-black;
        font-family: 'Roboto', sans-serif;
        margin-bottom: .5rem;
        font-weight: 500;
        background-color: $base-yellow;
        box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
        -webkit-box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
        -moz-box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07), 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
    }
    .btn-primary-yellow:hover, .btn-primary-yellow:active{
        background-color: $base-yellow-hover;
        color: $base-true-black;
    }

    @media only screen and (max-width: $base-width-sm) {
        header {
            min-height: 180px;
        }
    }

    .customize-form{
        .form-group {
            font-family: 'Roboto', sans-serif;
            position: relative;
            .form-label {
                color: $base-gray;
                font-weight: 700;
            }
            .form-control{
                border: 2px solid #C4C4C4;
                padding: 5px 12px;
                font-size: 14px;
                font-weight: 600;
            }

            textarea.form-control {
                min-height: 100px;
            }

            .form-icon {
                position: absolute;
                right: 10px;
                top: 40px;
            }

            .form-icon.form-calendar::after{
                display: block;
                content: '';
                width: 15px;
                height: 15px;
                background-image: url('../images/icon/calendar_imput.svg');
                background-repeat: no-repeat;
                background-size: cover;
            }

            .text-hidden{
                font-weight: 700;
                font-size: 15px;
                font-style: normal;
            }

            small{
                font-family: 'Open Sans', sans-serif;
            }

            .fileCustom {
                input:read-only {
                    background-color: white;
                    border-radius: .25rem;
                    margin-right: 85px;
                }

                label {
                    position: absolute;
                    right: 0;
                    background: #888888;
                    border-radius: 3px;
                    margin-bottom: 0;
                    color: white;
                    padding: 5px 10px;
                }
            }

            .custom.custom-file {
                position: relative;
                width: 100%;
                .custom-file-label{
                    border: 2px solid #C4C4C4;
                    padding: 5px 12px;
                    font-size: 14px;
                    font-weight: 600;
                    // width: 90%;
                }

                .custom-file-label::after {
                    background: #888888;
                    border-radius: 3px;
                    // right: 0;
                    color: white;
                }
            }
        }
    }
}

.content-detail {
    .content-detail-header {
        // text-align: left;
        color: $base-true-black;

        img {
            max-width: 100%;
        }

        .content-detail-title {
            font-size: 20px;
            font-family: 'Roboto', sans-serif;
            font-weight: 600;
        }

        .content-detail-publish {
            font-size: 15px;
            font-weight: 600;
            font-family: 'Roboto', sans-serif;
            color: $base-gray;
        }

        .content-detail-publish::before{
            display: inline-block;
            content: '';
            background-image: url('../images//icon/callendar.svg');
            width: 12px;
            height: 12px;
            background-size: cover;
            background-repeat: no-repeat;
            vertical-align: baseline;
            margin-right: 5px;
        }
    }

    .content-detail-body {
        text-align: justify;
        font-size: 13px;
        color: $base-half-black;
        font-weight: normal;
        font-family: 'Roboto', sans-serif;
    }

    .content-detail-footer {
        text-align: center;

        .alert.alert-custom {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-between;
            min-height: 70px;
            background: #FFFFFF;
            box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25)!important;
            border-radius: 3px;
            margin-bottom: 20px;
            border-color: #FFFFFF!important;

            .alert-heading{
                font-size: 14px;
                margin: 0;
                color: $base-gray;
                font-weight: 600;
            }

            .btn-danger{
                background: #CA2D2D;
                // box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.25);
                border-radius: 3px;
                padding: 10px 16px;
                font-weight: 600;
                color: #FFFFFF;
                font-family: 'Roboto', sans-serif;
                font-size: 13px;
                margin-left: 8px;
                min-width: 100px;
                max-width: 100px;
            }

            .btn-danger:hover{
                background: #a71d1d;
            }

            .btn-light {
                background: #888888;
                // box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.25);
                border-radius: 3px;
                padding: 10px 16px;
                font-weight: 600;
                color: #FFFFFF;
                font-family: 'Roboto', sans-serif;
                font-size: 13px;
                min-width: 100px;
                max-width: 100px;
                margin-left: 8px;
                display: flex;
                align-items: center;
            }

            .label-info {
                font-size: 13px;
                text-align: right;
                margin-left: 10px;
                background: #ca2d2d;
                color: white;
                text-align: center;
                font-family: 'Open Sans', sans-serif;
                box-shadow: 3px 4px 10px rgba(0, 0, 0, 0.25);
                border-radius: 5px;
                padding: 3px 4px;
                font-weight: 500;
                opacity: 0.5;
            }

            .alertinfo {
                font-weight: 500;
                color: #888;
                font-size: 15px;
                border-top: 1.5px solid #c4c4c4;
                margin-top: 10px;
                padding-top: 5px;
            }
        }

        .btn-primary-yellow {
            width: 250px;
            height: 40px;
            border-radius: 6px;
            border: none;
            color: $base-black;
            font-family: 'Poppins-Medium';
            margin-bottom: .5rem;
            background-color: $base-yellow;
            box-shadow: 0px 4px 16px rgba(0,0,0,0.25);
            -webkit-box-shadow: 0px 4px 16px rgba(0,0,0,0.25);
            -moz-box-shadow: 0px 4px 16px rgba(0,0,0,0.25);
        }

        table {
            .btn-primary {
                max-width: 150px;
                height: auto;
            }
        }

        .alert-heading {
            font-size: 1.2rem;
        }
    }
}
.information-data {
    .title-form{
        font-size: 17px;
    }
    
    .title {
        text-align: center;
        margin-bottom: 11px;
        font-size: 22px;
        font-weight: 700;
        color: $base-true-black;
    }
    
    .form-group {
        font-size: 14px;
    }
    
    .form-group .form-label{
        font-weight: 600;
    }
    .text-information {
        ul {
            padding-left: 1.5rem;
        }
    }
}

.card.card-custombs{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F9F7F7;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 12px;
    color: #888888;
    .alert-body{
        span{
            font-size: 12px;
            font-weight: normal;
        }
    }
}

.info-naskah{
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 15px;
    color: #888;
    .double-dot{
        span:first-child{
            width: 185px;
            display: inline-block;
        }
        span:first-child:after{
            content: ':';
            float: right;
            display: inline-block;
            margin-right: 5px;
        }
        span.danger{
            color: #CA2D2D;
            text-transform: capitalize;
        }
    }
}

.card-naskah{
    border-width: 0!important;
    background-color: #F6F6F6!important;
    padding: 13px 25px;
    .card-body{
        padding: 0;
    }
}

.card-naskah.bg-white{
    background-color: #FFFFFF!important;
}

.listinfo {
    ol,ul {
        padding-left: 25px;
    }
}